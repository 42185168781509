<template>
    <div class="individual-order__wrapper">
        <div class="individual-order__inner" v-if="form">
            <h2>{{ $t('individualOrder.title') }}</h2>
            <!-- Hide sections/selections -->
            <CreationSection :title="$t('individualOrder.hideSections')" class="border">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="button in renderHideSectionsButtons(form)"
                        :key="button.id"
                        :active="!form[button.field]"
                        @click="handelSelect(!button.value, button.field)"
                        pattern="toggle"
                    >
                        {{ button.name }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Allergens -->
            <CreationSection :title="$t('common.allergens')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="allergen in allergens"
                        :key="allergen.id"
                        :active="form.allergensIds.includes(allergen.id)"
                        @click="handelToggle(allergen.id, 'allergensIds')"
                        pattern="toggle"
                    >
                        <template #icon>
                            <VegetarianSvg />
                        </template>
                        {{ $filters.getTranslationByKey(allergen.name) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Disliked products -->
            <CreationSection :title="$t('individualOrder.dislikedProducts')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="product in products"
                        :key="product.id"
                        :active="form.productsIds.includes(product.id)"
                        @click="handelToggle(product.id, 'productsIds')"
                        pattern="toggle"
                    >
                        <template #icon>
                            <VegetarianSvg />
                        </template>
                        {{ $filters.getTranslationByKey(product.name) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Diet plans -->
            <CreationSection :title="$t('individualOrder.dietPlans')" class="border">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="dietPlan in dietPlans"
                        :key="dietPlan.id"
                        :active="form.dietPlansIds.includes(dietPlan.id)"
                        @click="handelToggle(dietPlan.id, 'dietPlansIds')"
                        pattern="toggle"
                    >
                        <template #icon>
                            <VegetarianSvg />
                        </template>
                        {{ $filters.getTranslationByKey(dietPlan.name) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Order periods -->
            <CreationSection :title="$t('individualOrder.orderPeriods')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="period in periods"
                        :key="period.id"
                        :active="form.periodsIds.includes(period.id)"
                        @click="handelToggle(period.id, 'periodsIds')"
                        pattern="toggle"
                    >
                        {{ $filters.getTranslationByKey(period.name) }}
                    </CRButton>
                </template>
                <CRButton class="btn" @click="isPeriodModalOpened = true">
                    {{ $t('buttons.addNew') }}
                </CRButton>
                <CreatePeriodModal
                    v-if="isPeriodModalOpened"
                    @close="isPeriodModalOpened = false"
                    @newPeriod="addNewPeriod"
                />
            </CreationSection>

            <!-- Other requests -->
            <CreationSection :title="$t('individualOrder.otherRequests')">
                <CRCheckbox
                    class="checkbox"
                    v-for="addition in additions"
                    :key="addition.id"
                    :checked="form.additionsIds.includes(addition.id)"
                    :value="addition.id"
                    @change="handelToggle(addition.id, 'additionsIds')"
                >
                    {{ $filters.getTranslationByKey(addition.name) }}
                </CRCheckbox>
            </CreationSection>

            <!-- Discount -->
            <CreationSection :title="$t('mealKits.applyDiscount')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="discount in discounts"
                        :key="discount.id"
                        :active="form.discountId === discount.id"
                        @click="handelSelect(discount.id, 'discountId')"
                        pattern="section"
                    >
                        {{ discount.amount }}{{ getSign(discount.type) }}
                    </CRButton>
                </template>
            </CreationSection>

            <CreationSection :title="'Image'">
                <ImageCard
                    class="image-card"
                    v-if="images[0]"
                    @removePicture="removePicture"
                    :index="0"
                    :image="images[0]"
                />

                <CRButton v-else upload pattern="thin" @handleUpload="handleUpload">
                    {{ $t('otherSettings.addThumbnail') }}
                </CRButton>
            </CreationSection>

            <!-- Show/hide -->
            <CreationSection :title="$t('mealKits.showHideAfterCreating')" class="border">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="button in hideMealKitButtonsData"
                        :key="button.value"
                        :active="form.isHidden === button.value"
                        @click="handelSelect(button.value, 'isHidden')"
                        pattern="section"
                    >
                        {{ button.title }}
                    </CRButton>
                </template>
            </CreationSection>

            <CRButton
                class="update-btn"
                @click="updateIndividualOrder"
                :disabled="createBtn.isDisable"
                :loading="createBtn.isLoading"
            >
                {{ $t('buttons.update') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CreatePeriodModal from '@/components/Modals/CreatePeriodModal';
    import ImageCard from '@/components/Common/ImagesUploadSection/ImageCard.vue';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import {
        IndividualOrderSettingsApi,
        DiscountApi,
        AdditionApi,
        AllergenApi,
        DietPlanApi,
        ProductApi,
        PictureApi,
        PeriodApi,
    } from '@/api';
    import { mapGetters, mapActions } from 'vuex';
    import { renderHideSectionsButtons } from '@/config/individualOrderSettings/individualOrderSettings';
    import { hideMealKitButtonsData } from '@/config/mealKits/mealKits';
    import { getSign } from '@/helpers/SignHelper';
    import { AllergenTypeEnum } from '@/utils/enums';
    import { handleUploadImage } from '@/helpers/UploadImageHelper';

    export default {
        name: 'UpdateIndividualOrder',
        components: { CRButton, CreationSection, VegetarianSvg, CRCheckbox, CreatePeriodModal, ImageCard },
        data() {
            return {
                renderHideSectionsButtons,
                hideMealKitButtonsData,
                getSign,

                // fetched states
                allergens: [],
                additions: [],
                products: [],
                dietPlans: [],
                periods: [],
                discounts: [],

                createBtn: {
                    isLoading: false,
                    isDisable: true,
                },

                isPeriodModalOpened: false,

                // data
                form: null,
                images: [],
            };
        },

        watch: {
            'form.periodsIds.length': {
                handler(value) {
                    !value ? (this.createBtn.isDisable = true) : (this.createBtn.isDisable = false);
                },
                immediate: true,
            },
        },

        computed: {
            ...mapGetters('mealKit', ['individualOrderSettings']),
        },

        methods: {
            ...mapActions('mealKit', ['getIndividualOrderSettings']),

            // Meal kit creation
            async updateIndividualOrder() {
                try {
                    this.createBtn.isLoading = true;

                    await IndividualOrderSettingsApi.update(this.form, this.individualOrderSettings.id);

                    this.$router.push({ name: 'allAdminMealKits' });
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.createBtn.isLoading = false;
                }
            },

            // Common select handlers
            handelSelect(value, field) {
                this.form[field] = value;
            },

            handelToggle(value, field) {
                if (this.form[field].includes(value)) {
                    this.form[field] = this.form[field].filter((itemId) => itemId !== value);
                } else {
                    this.form[field].push(value);
                }
            },

            // Get data
            async getDataForSelections(Api, field) {
                try {
                    const response = await Api.getAll();
                    this[field] = response.data;

                    if (field === 'discounts') {
                        this[field].unshift({
                            id: null,
                            amount: this.$t('common.none'),
                            type: '',
                        });
                    }

                    if (field === 'allergens') {
                        this[field] = response.data.filter((allergen) => allergen.type !== AllergenTypeEnum.ORDER);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            // picture
            async handleUpload($event) {
                try {
                    handleUploadImage($event.target.files, this.images);

                    this.images = await this.uploadImage($event.target.files[0], this.individualOrderSettings.id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async uploadImage(image, individualOrderSettingsId) {
                let formData = new FormData();

                formData.append('pictures[]', image);
                formData.append('picturable_type', 'individual_order_settings');
                formData.append('picturable_id', individualOrderSettingsId);

                const response = await PictureApi.store(formData);

                return response.data;
            },

            async removePicture({ value, id }) {
                try {
                    this.images = this.images.filter((item, index) => index !== value);
                    await PictureApi.destroy(id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            // addNewPeriod
            addNewPeriod(period) {
                this.periods.push(period);
            },

            // prepareInitialDataForPage
            prepareInitialDataForPage() {
                const {
                    periods,
                    additions,
                    allergens,
                    dietPlans,
                    dislikedProducts,
                    withPersonalKit,
                    withAllergens,
                    withDislikedProducts,
                    withMacronutrients,
                    withDietPlans,
                    withMealNumber,
                    isHidden,
                    discount,
                    picture,
                } = this.individualOrderSettings;

                const periodsIds = periods.map((period) => period.id);
                const additionsIds = additions.map((period) => period.id);
                const allergensIds = allergens.map((period) => period.id);
                const dietPlansIds = dietPlans.map((period) => period.id);
                const dislikedProductsIds = dislikedProducts.map((period) => period.id);

                this.form = {
                    withPersonalKit,
                    withAllergens,
                    withDislikedProducts,
                    withMacronutrients,
                    withDietPlans,
                    withMealNumber,
                    isHidden,
                    discountId: discount ? discount.id : null,

                    periodsIds: periodsIds,
                    allergensIds: allergensIds,
                    additionsIds: additionsIds,
                    productsIds: dislikedProductsIds,
                    dietPlansIds: dietPlansIds,
                };

                this.images = [picture];
            },
        },

        async created() {
            await this.getDataForSelections(AllergenApi, 'allergens');
            await this.getDataForSelections(AdditionApi, 'additions');
            await this.getDataForSelections(DietPlanApi, 'dietPlans');
            await this.getDataForSelections(ProductApi, 'products');
            await this.getDataForSelections(PeriodApi, 'periods');
            await this.getDataForSelections(DiscountApi, 'discounts');
        },

        async mounted() {
            if (!this.individualOrderSettings) {
                await this.getIndividualOrderSettings();
            }

            this.prepareInitialDataForPage();
        },
    };
</script>

<style lang="scss" scoped>
    .individual-order__wrapper {
        padding-bottom: 30px;

        h2 {
            margin: 20px 0;
        }

        .checkbox {
            margin-bottom: 15px;
        }

        .border {
            border-bottom: 1px solid $grey-form;
        }

        .update-btn {
            margin-top: 30px;
        }

        .image-card {
            max-width: 360px;
            height: 250px;
        }
    }

    @include media($lg) {
        .individual-order__wrapper {
            padding-bottom: 60px;

            .individual-order__inner {
                max-width: 700px;
            }

            h2 {
                font-size: $font-34;
            }
        }
    }
</style>
