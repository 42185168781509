<template>
    <div class="tag-container" :class="containerStyle" @click="$emit('click')">
        <VegetarianSvg class="icon" v-if="icon === 'vegetarian'" />
        <LocationSvg class="icon location" v-if="icon === 'location'" />
        <DocumentBlankSvg class="icon-blank" v-if="icon === 'document-blank'" />
        <DocumentFilledSvg class="icon" v-if="icon === 'document-filled'" />
        <HeartSvg class="icon" v-if="icon === 'heart'" />
        <WebSvg class="icon" v-if="icon === 'web'" />
        <span class="tag-container__text" :class="textStyle"><slot /></span>
        <div class="tag-container__edit-section" v-if="editAllowed" @click.stop="$emit('edit')">
            <PenSvg class="icon" />
        </div>
        <CrossSvg class="icon cross" v-if="deleteAllowed" @click.stop="$emit('delete')" />
    </div>
</template>

<script>
    import LocationSvg from '@/assets/icons/tags/location.svg?inline';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import DocumentBlankSvg from '@/assets/icons/tags/document-blank.svg?inline';
    import DocumentFilledSvg from '@/assets/icons/tags/document-filled.svg?inline';
    import HeartSvg from '@/assets/icons/tags/heart.svg?inline';
    import CrossSvg from '@/assets/icons/buttons/cross-black.svg?inline';
    import WebSvg from '@/assets/icons/tags/web.svg?inline';
    import PenSvg from '@/assets/icons/buttons/pen-white.svg?inline';

    export default {
        name: 'CRTag',
        components: {
            LocationSvg,
            VegetarianSvg,
            DocumentBlankSvg,
            DocumentFilledSvg,
            CrossSvg,
            HeartSvg,
            PenSvg,
            WebSvg,
        },
        props: {
            icon: {
                type: String,
                default: null,
                validator: (value) => value.match(/(vegetarian|location|document-filled|document-blank|heart|web)/),
            },
            type: {
                type: String,
                default: 'primary',
                validator: (value) =>
                    value.match(/(primary|secondary|additional|special|danger|awaiting|danger-fill|success|grey)/),
            },
            deleteAllowed: {
                type: Boolean,
                default: false,
            },
            editAllowed: {
                type: Boolean,
                default: false,
            },
            svgColor: {
                type: String,
                default: '#00A755',
            },
        },
        emits: ['click', 'edit', 'delete'],
        computed: {
            containerStyle() {
                return [`tag-container__${this.type}`, this.icon && 'tag-container__with__icon'];
            },
            textStyle() {
                return [`tag-container__text__${this.type}`, this.icon && 'tag-container__text__icon'];
            },
        },
    };
</script>

<style scoped lang="scss">
    .tag-container {
        @include row-align-center;
        text-align: center;

        transition-duration: 0.4s;

        padding: 2px 10px;
        border-radius: $br-10;
    }

    .tag-container__text {
        overflow-wrap: break-word;

        font-weight: bold;
        font-size: $font-14;
    }

    .tag-container__primary {
        background: $secondary;
    }

    .tag-container__text__primary {
        color: $white;
    }

    .tag-container__secondary {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
        background: $white;
    }

    .tag-container__text__secondary {
        color: $primary;
    }

    .tag-container__danger2 {
        background: #ffe8e8;
    }

    .tag-container__additional {
        background: $secondary-500;
    }

    .tag-container__text__additional {
        color: $secondary;
    }

    .tag-container__special {
        background: $yellow;
    }

    .tag-container__text__special {
        color: $white;
    }

    .tag-container__danger {
        background: $white;
        box-shadow: $box-shadow-dark;
    }

    .tag-container__text__danger {
        color: $danger;
    }

    .icon.icon-blank {
        width: 12px;
        height: 12px;
    }

    .cross {
        cursor: pointer;
        margin: 0 !important;
        margin-left: 10px !important;
    }

    .tag-container__edit-section {
        @include row-align-center;

        cursor: pointer;

        position: relative;

        padding-left: 10px;
        margin-left: 3px;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 30px;
            position: absolute;

            background: $white;
            left: 0;
        }

        .icon {
            margin: 0;
        }
    }

    .icon.location {
        margin-right: 6px;
    }

    .icon {
        width: 12px;
        height: 12px;
    }

    .tag-container__with__icon {
        .tag-container__text {
            padding-right: 10px;
            flex-grow: 1;
        }
    }

    :deep(.icon) {
        margin-right: 10px;

        path {
            fill: v-bind(svgColor);
        }
    }

    // awaiting
    .tag-container__awaiting {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $yellow;
    }

    .tag-container__text__awaiting {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
    }

    // danger-fill
    .tag-container__danger-fill {
        background: $danger;
    }

    .tag-container__text__danger-fill {
        color: $black;
    }

    // success
    .tag-container__success {
        background: $primary;
    }

    .tag-container__text__success {
        color: $white !important;
    }

    // grey
    .tag-container__grey {
        background: $grey-form-label;
    }

    .tag-container__text__grey {
        color: $black;
    }
</style>
