<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ $t('periods.createPeriod') }}</h2>
            <CRInput
                class="input"
                v-model.number="amount"
                type="number"
                :label="$t('common.daysAmount')"
                :placeholder="$t('placeholder.enterAmountOfDays')"
                :errors="errors?.amount"
                @onChange="$filters.clearError(errors, 'amount')"
            />
            <CRButton @click="createPeriod" :disabled="!amount">
                {{ $t('buttons.add') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { PeriodApi } from '@/api';

    export default {
        name: 'CreatePeriodModal',
        components: { BasicModal, CRButton, CRInput },
        emits: ['close', 'newPeriod'],
        data() {
            return {
                isLoading: false,

                amount: '',

                errors: {},
            };
        },
        methods: {
            async createPeriod() {
                try {
                    const response = await PeriodApi.store({ amount: this.amount });
                    this.$emit('newPeriod', response.data);

                    this.amount = '';

                    this.$emit('close');
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start-justify-start;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        h2 {
            margin-bottom: 10px;
        }

        .input {
            margin-top: 24px;
            margin-bottom: 22px;
        }

        .error {
            width: 100%;
        }
    }
</style>
