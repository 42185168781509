import { translation } from '@/helpers/TranslationHelper';

export const renderHideSectionsButtons = (form) => {
    const { withPersonalKit, withAllergens, withDislikedProducts, withMacronutrients, withDietPlans, withMealNumber } =
        form;

    return [
        {
            id: 1,
            name: translation('individualOrder.personalKit'),
            field: 'withPersonalKit',
            value: withPersonalKit,
        },
        {
            id: 2,
            name: translation('common.allergens'),
            field: 'withAllergens',
            value: withAllergens,
        },
        {
            id: 3,
            name: translation('individualOrder.dislikedProducts'),
            field: 'withDislikedProducts',
            value: withDislikedProducts,
        },
        {
            id: 4,
            name: translation('common.macronutrients'),
            field: 'withMacronutrients',
            value: withMacronutrients,
        },
        {
            id: 5,
            name: translation('individualOrder.dietPlans'),
            field: 'withDietPlans',
            value: withDietPlans,
        },
        {
            id: 6,
            name: translation('common.mealNumber'),
            field: 'withMealNumber',
            value: withMealNumber,
        },
    ];
};
