import { translation } from '@/helpers/TranslationHelper';

export const mealKitButtonsData = [
    { title: translation('mealKits.regularKit'), value: false },
    { title: translation('mealKits.uniqueKit'), value: true },
];

export const hideMealKitButtonsData = [
    { title: translation('buttons.show'), value: false },
    { title: translation('buttons.hide'), value: true },
];

export const deliveryInputsData = [
    {
        id: 1,
        value: 'lastOrderDay',
        label: translation('common.lastOrderDay'),
        errors: 'lastOrderDay',
    },
    {
        id: 2,
        value: 'deliveryDate',
        label: translation('common.deliveryDate'),
        errors: 'deliveryDate',
    },
];
