<template>
    <div class="image-card" :style="backgroundStyles(renderImage(image))">
        <CRTag v-if="index === 0" class="thumbnail-tag" type="secondary">
            {{ $t('common.thumbnail') }}
        </CRTag>
        <CRButton @click="removePicture" close class="cross-btn" />
        <CRButton @click="makeThumbnail" class="make-thumbnail-btn" v-if="index !== 0" size="xx-small">
            {{ $t('buttons.makeThumbnail') }}
        </CRButton>
    </div>
</template>

<script>
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import CRTag from '@/components/Common/Tags/CRTag.vue';
import { backgroundStyles } from '@/helpers/CssHelper';

export default {
    name: 'ImageCard',
    components: { CRButton, CRTag },
    props: {
        index: {
            type: Number,
            default: 0,
        },
        image: {
            type: Object,
            default: () => { },
        },
    },
    emits: ['removePicture', 'makeThumbnail'],
    data() {
        return {
            backgroundStyles,
        };
    },
    methods: {
        renderImage(file) {
            return file?.id ? file.picturePath.inner : window.URL.createObjectURL(file);
        },

        removePicture() {
            this.$emit('removePicture', { value: this.index, id: this.image?.id });
        },

        makeThumbnail() {
            this.$emit('makeThumbnail', { index: this.index, id: this.image?.id });
        },
    },
};
</script>

<style lang="scss" scoped>
.image-card {
    @include row-align-end;
    position: relative;
    padding: 6px;

    width: 100%;
    height: 110px;

    border-radius: $br-15;

    margin-right: 10px;
    margin-bottom: 10px;

    transition: all 0.2s linear;

    .cross-btn {
        position: absolute;
        right: 6px;
        top: 10px;
    }

    .thumbnail-tag {
        position: absolute;
        top: 10px;
        left: 6px;
    }

    .make-thumbnail-btn {
        width: 100%;
        opacity: 0;
    }

    &:hover .make-thumbnail-btn {
        opacity: 1;
    }
}
</style>
