<template>
    <label class="cr-checkbox" :class="checkBoxClasses">
        <input
            class="cr-checkbox__input"
            type="checkbox"
            :value="value"
            v-model="proxyChecked"
            @input="$emit('change', value)"
        />
        <div class="cr-checkbox__box">
            <div class="cr-checkbox__icon" />
        </div>
        <div class="cr-checkbox__text" :class="{ 'cr-checkbox__text_checked': checked }">
            <slot />
        </div>
        <div class="cr-radio_box__text__wrapper" v-if="isBox && $slots['content']">
            <slot name="content" />
        </div>
    </label>
</template>

<script>
    export default {
        name: 'CRCheckbox',
        props: {
            checked: {
                type: [Array, Boolean],
                default: false,
            },
            value: {
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isBox: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:checked', 'change'],
        computed: {
            proxyChecked: {
                get() {
                    return this.checked;
                },

                set(val) {
                    this.$emit('update:checked', val);
                },
            },
            checkBoxClasses() {
                return [
                    this.disabled && `cr-checkbox_disabled`,
                    this.checked && `cr-checkbox_checked`,
                    this.isBox && `cr-checkbox_box__wrapper`,
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cr-checkbox {
        display: flex;

        color: $white;

        cursor: pointer;

        &:hover {
            .cr-checkbox__box {
                border-color: $primary;
            }
        }
    }

    .cr-checkbox__input {
        display: none;
    }

    .cr-checkbox__box {
        @include row-align-center-justify-center;

        width: 18px;
        height: 18px;
        min-width: 18px;

        border: 1px solid $primary;
        border-radius: $br-3;

        padding: 2px;
    }

    .cr-checkbox__icon {
        opacity: 0;

        transition: all 0.2s linear;
    }

    .cr-checkbox__text {
        padding-left: 10px;
        color: $black;
        font-weight: 400;
        font-size: $font-16;
    }

    .cr-checkbox__text_checked {
        font-weight: 700;
    }

    .cr-checkbox_checked {
        position: relative;

        .cr-checkbox__icon {
            opacity: 1;

            transition: all 0.2s linear;
            width: 100%;
            height: 100%;

            background: $primary;
            border-radius: $br-2;
        }
    }

    // cr-checkbox_box
    .cr-checkbox_box__wrapper {
        padding: 8px 10px;

        border: 2px solid $primary;
        border-radius: $br-10;

        &.cr-checkbox_checked {
            background: $primary-400;
        }

        .cr-checkbox__box {
            display: none;
        }

        .cr-radio_box__text__wrapper {
            width: 100%;
        }
    }
</style>
